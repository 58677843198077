<template>
<div class="etx-container Container" :class="paragraphClassList">
	<div class="etx-center Center">
		
		<div class="etx-flex Flex gv-0">
			<div class="etx-flex__item Flex__item tab-m:c-10 tab-m:ml-1">
				
				<div class="etx-video Video" :class="entranceClassList">
					<HelperHtmlRenderer :htmlContent="embed"/>
				</div>
				
			</div>
		</div>
				
	</div>
</div>
</template>
  
<script lang="ts" setup>

const props = defineProps<{ 
  embed: string
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'video_content_width',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})


</script>