<template>

  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex tab-s:gv-0">
        <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-8">

          <NuxtPageDependency>
            <Breadcrumb :isEditing="isEditing" :links="breadcrumb" v-if="showBreadcrumb"/>
          </NuxtPageDependency>

          <div v-if="heading || headingSubline" :class="entranceClassList" class="etx-text Text">
            <h1 v-if="heading">{{ heading }}</h1>
            <p class="font-h1 alt" v-if="headingSubline">{{ headingSubline }}</p>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>
  defineProps<{
    showBreadcrumb: boolean
    heading?: string
    headingSubline?: string
  }>()

  const breadcrumb = useBreadcrumbLinks()

  const { options, isEditing } = defineBlokkli({
    bundle: 'hero_text_standard',
    globalOptions: ['spacingBottom', 'backgroundColor'],
    options: {
      spacingTop: {
        type: 'radios',
        label: 'Oben',
        default: 'pt-100',
        group: 'Innenabstände oben und unten',
        options: {
          'pt-0': '0',
          'pt-25': '25',
          'pt-50': '50',
          'pt-75': '75',
          'pt-100': '100',
          'pt-200': '200',
        },
      },
    },
  })

  const paragraphClassList = computed(() => {

    const classList = []

    if (options.value.spacingTop) {
      classList.push(options.value.spacingTop)
    }

    if (options.value.spacingBottom) {
      classList.push(options.value.spacingBottom)
    }

    if (options.value.backgroundColor !== 'white') {
      classList.push(options.value.backgroundColor)
    }

    return classList
  })

  const entranceClassList = computed(() => {

    const classList = []

    if (!isEditing) {
      classList.push('will-appear', 'move-up')
    }

    return classList
  })

</script>