<template>
    <p>Störer: Downloads, 2 Spalten</p>
  </template>
  
  <script lang="ts" setup>
  
  const { options, isEditing } = defineBlokkli({
    bundle: 'downloads_2_col_titlebar',
  })
  
  </script>
  