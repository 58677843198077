<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex gh-150 mob-l:c-2 tab-m:c-3" v-if="iconTeasers?.length > 0">

        <div v-for="iconTeaser in iconTeasers" class="etx-flex__item Flex__item">

          <component :is="iconTeaser.link ? 'a' : 'div'" class="etx-item Item ItemLink" :class="entranceClassList"
            v-bind="iconTeaser.link ? { href: iconTeaser?.link?.uri?.path, title: iconTeaser.alt } : {}">

            <div v-if="iconTeaser.icon" class="etx-item__icon Item__icon">
              <div class="etx-icon Icon larger">
                <img :src="iconTeaser?.icon?.url?.path" />
              </div>
            </div>

            <div v-if="iconTeaser.heading || (iconTeaser.label && iconTeaser.link)" class="etx-item__text Item__text">
              <p v-if="iconTeaser.heading" class="font-weight-bold font-size-large">{{ iconTeaser.heading }}</p>
              <div v-if="iconTeaser.link && iconTeaser.label" class="etx-button Button minimal">
                <div class="etx-button__text Button__text">
                  <span>{{ iconTeaser.label }}</span>
                </div>
                <div class="etx-button__icon Button__icon">
                  <i class="far fa-long-arrow-right"></i>
                </div>
              </div>
            </div>

          </component>

        </div>

      </div>

    </div>
  </div>

</template>

<script lang="ts" setup>

const props = defineProps<{
  iconTeasers: Array<any>
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'icon_teaser_3_col',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

</script>