<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">
      <div class="etx-flex Flex tab-s:c-2 tab-l:c-3 gh-200 gv-200">
        <div v-for="textnumberelement in textnumberelements" class="etx-flex__item Flex__item">
          <div :class="entranceClassList" class="etx-card Card">

            <div class="etx-card__item Card__item mob-l:ph-200 pv-0" v-if="textnumberelement.number">
              <div :class="entranceClassList" class="etx-text Text a-center">
                <p class="font-h1 font-weight-bold will-countUp" :data-count="textnumberelement.number">0</p>
              </div>
            </div>

            <div class="etx-card__item Card__item mob-l:ph-200 pt-25 pb-0" v-if="textnumberelement.text">
              <div :class="entranceClassList" class="etx-text Text a-center">
                <p class="font-h4">{{ textnumberelement.text }}</p>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  textnumberelements: Array<any>
}>()
const { options, isEditing } = defineBlokkli({
  bundle: 'number_3_col',
  options: {
    spacingTop: {
      type: 'radios',
      label: 'Oben',
      default: 'pt-75',
      group: 'Innenabstände oben und unten',
      options: {
        'pt-0': '0',
        'pt-25': '25',
        'pt-50': '50',
        'pt-75': '75',
        'pt-100': '100',
        'pt-200': '200',
      },
    },
    spacingBottom: {
      type: 'radios',
      label: 'Unte',
      default: 'pb-75',
      group: 'Innenabstände oben und unten',
      options: {
        'pb-0': '0',
        'pb-25': '25',
        'pb-50': '50',
        'pb-75': '75',
        'pb-100': '100',
        'pb-200': '200',
      },
    },
    backgroundColor: {
      type: 'radios',
      label: 'Hintergrundfarbe Color',
      default: 'bg-grey-1',
      displayAs: 'colors',
      options: {
        'white': { class: 'bg-grey-1', label: 'Weiss' },
        'bg-grey-1': { class: 'bg-grey-1', label: 'Hellgrau' },
        'bg-primary': { class: 'bg-primary', label: 'Grün' },
        'bg-secondary': { class: 'bg-secondary', label: 'Blau' },
      },
    },
  },
})
const paragraphClassList = computed(() => {
  const classList = []
  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }
  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }
  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }
  return classList
})
const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})
</script>