<script lang="ts">
import { defineComponent, h } from 'vue';
// Define props for the component
const props = defineComponent({
  props: {
    htmlContent: {
      type: String,
      required: true,
    },
  },
});
// Render function to inject raw HTML
const HtmlRenderer = defineComponent({
  props: props.props,
  render() {

    const tagRegex = /<(\w+)([^>]*)>(.*?)<\/\1>/g; // Matches tags and their content
    const attributesRegex = /(\w+)=["'](.*?)["']/g; // Matches attributes within a tag
    const rootElements: Array<{ tag: string; attributes?: Record<string, any>; content: string }> = [];

    let match;
    while ((match = tagRegex.exec(this.htmlContent)) !== null) {
      const [_, tag, attributesString, content] = match;

      // Parse attributes
      const attributes: Record<string, any> = {};
      if (attributesString.trim()) {
        let attrMatch;
        while ((attrMatch = attributesRegex.exec(attributesString)) !== null) {
          const [__, attrName, attrValue] = attrMatch;
          attributes[attrName] = isNaN(Number(attrValue)) ? attrValue : Number(attrValue); // Convert numeric values
        }
      }

      rootElements.push({
        tag,
        ...(Object.keys(attributes).length > 0 && { attributes }),
        content: content.trim(),
      });
    }

    return rootElements.map((element) => (h(element.tag, { innerHTML: element.content.replace(/<span[^>]*>|<\/span>/g, ''), ...element.attributes })));
  },
});
export default HtmlRenderer;
</script>