<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex c-auto nowrap tab-s:wrap gh-200 gv-150 mob-l:gh-100 mob-l:gv-0">
        <div class="etx-flex__item Flex__item mob-l:c-2 shrink-0">
          <a v-if="showContentLink" class="etx-icon Icon larger font-color-secondary LinkAnchor" :class="entranceClassList" href="#content" title="">
            <i class="fal fa-arrow-down"></i>
          </a>
        </div>

        <div class="etx-flex__item Flex__item mob-l:c-10 tab-s:c-9 tab-l:c-8 tab-s:ml-1 desk-s:ml-2 grow-1">

          <div class="etx-flex Flex mob-m:c-2 tab-m:c-3 gh-50 gv-50 tab-s:gh-100 tab-s:gv-100 av-stretch">

            <div class="etx-flex__item Flex__item c-12">
              <NuxtPageDependency>
                <Breadcrumb :isEditing="isEditing" :links="breadcrumb" />
              </NuxtPageDependency>
            </div>

            <div v-for="teaser in activeChildLinks" class="etx-flex__item Flex__item">

              <div class="etx-card Card CardLink bg-secondary has-link" :class="entranceClassList">
                <a class="etx-card__link Card__link" :href="teaser?.link?.url?.path" :title="teaser?.link?.label"></a>

                <div class="etx-card__item Card__item pv-0 grow-1"></div>

                <div class="etx-card__item Card__item ph-75 pv-75">

                  <div class="etx-item Item fullwidth av-bottom">
                    <div class="etx-item__text Item__text">
                      <p class="font-size-large font-weight-bold">{{ teaser?.link?.label }}</p>
                    </div>

                    <div class="etx-item__icon Item__icon">

                      <div class="Icon">
                        <i class="far fa-long-arrow-right"></i>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

import { ref, onMounted } from 'vue';
import { useEventBus } from '~/composables/useEventBus';

const showContentLink = ref(true);
const eventBus = useEventBus();


const breadcrumb = useBreadcrumbLinks()

const { activeChildLinks } = await useMainMenuLinks()

const { options, isEditing } = defineBlokkli({
  bundle: 'navigation_breadcrumb_teaser',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

onMounted(() => {
  showContentLink.value = eventBus.value.length > 0;
});

</script>