
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_from_library_index from '/app/node_modules/@blokkli/editor/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_anchor_index from '/app/components/Paragraph/Anchor/index.vue'
import BlokkliComponent_anchor_content_index from '/app/components/Paragraph/AnchorContent/index.vue'
import BlokkliComponent_hero_text_standard_index from '/app/components/Paragraph/HeroTextStandard/index.vue'
import BlokkliComponent_downloads_2_col_titlebar_index from '/app/components/Paragraph/Downloads2ColTitlebar/index.vue'
import BlokkliComponent_disruptor_1_col_titlebar_index from '/app/components/Paragraph/Disruptor1ColTitlebar/index.vue'
import BlokkliComponent_icon_teaser_3_col_index from '/app/components/Paragraph/IconTeaser3Col/index.vue'
import BlokkliComponent_icon_teaser_2_col_titlebar_index from '/app/components/Paragraph/IconTeaser2ColTitlebar/index.vue'
import BlokkliComponent_icon_text_2_col_titlebar_tabs_index from '/app/components/Paragraph/IconText2ColTitlebarTabs/index.vue'
import BlokkliComponent_icon_text_2_col_titlebar_index from '/app/components/Paragraph/IconText2ColTitlebar/index.vue'
import BlokkliComponent_icon_text_3_col_index from '/app/components/Paragraph/IconText3Col/index.vue'
import BlokkliComponent_icon_text_3_col_tabs_index from '/app/components/Paragraph/IconText3ColTabs/index.vue'
import BlokkliComponent_image_fall_off_index from '/app/components/Paragraph/ImageFallOff/index.vue'
import BlokkliComponent_image_slider_fall_off_left_index from '/app/components/Paragraph/ImageSliderFallOffLeft/index.vue'
import BlokkliComponent_image_fall_off_stripe_index from '/app/components/Paragraph/ImageFallOffStripe/index.vue'
import BlokkliComponent_navigation_breadcrumb_teaser_index from '/app/components/Paragraph/NavigationBreadcrumbTeaser/index.vue'
import BlokkliComponent_quotes_slider_index from '/app/components/Paragraph/QuotesSlider/index.vue'
import BlokkliComponent_navigation_tabs_teaser_index from '/app/components/Paragraph/NavigationTabsTeaser/index.vue'
import BlokkliComponent_teaser_3_col_lg_index from '/app/components/Paragraph/Teaser3ColLarge/index.vue'
import BlokkliComponent_references_slider_index from '/app/components/Paragraph/ReferencesSlider/index.vue'
import BlokkliComponent_text_1_col_standard_index from '/app/components/Paragraph/Text1ColStandard/index.vue'
import BlokkliComponent_number_3_col_index from '/app/components/Paragraph/Number3Col/index.vue'
import BlokkliComponent_text_1_col_stripe_index from '/app/components/Paragraph/Text1ColStripe/index.vue'
import BlokkliComponent_text_1_col_titlebar_index from '/app/components/Paragraph/Text1ColTitlebar/index.vue'
import BlokkliComponent_text_2_col_scrollnavigation_index from '/app/components/Paragraph/Text2ColScrollnavigation/index.vue'
import BlokkliComponent_text_2_col_standard_index from '/app/components/Paragraph/Text2ColStandard/index.vue'
import BlokkliComponent_text_2_col_tabs_index from '/app/components/Paragraph/Text2ColTabs/index.vue'
import BlokkliComponent_text_2_col_tabs_titlebar_index from '/app/components/Paragraph/Text2ColTabsTitlebar/index.vue'
import BlokkliComponent_text_3_col_standard_index from '/app/components/Paragraph/Text3ColStandard/index.vue'
import BlokkliComponent_text_2_col_titlebar_index from '/app/components/Paragraph/Text2ColTitlebar/index.vue'
import BlokkliComponent_text_3_col_tabs_index from '/app/components/Paragraph/Text3ColTabs/index.vue'
import BlokkliComponent_text_image_2_col_standard_index from '/app/components/Paragraph/TextImage2ColStandard/index.vue'
import BlokkliComponent_text_number_3_col_index from '/app/components/Paragraph/TextNumber3Col/index.vue'
import BlokkliComponent_text_number_2_col_titlebar_index from '/app/components/Paragraph/TextNumber2ColTitlebar/index.vue'
import BlokkliComponent_text_image_2_col_bg_color_text_index from '/app/components/Paragraph/TextImage2ColBgColorText/index.vue'
import BlokkliComponent_blokkli_fragment_index from '/app/node_modules/@blokkli/editor/dist/runtime/components/Blocks/Fragment/index.vue'
import BlokkliComponent_video_content_width_index from '/app/components/Paragraph/VideoContentWidth/index.vue'
import BlokkliComponent_text_image_2_col_bg_color_index from '/app/components/Paragraph/TextImage2ColBgColor/index.vue'

const global: Record<string, any> = {
  block_from_library: BlokkliComponent_from_library_index,
  block_anchor: BlokkliComponent_anchor_index,
  block_anchor_content: BlokkliComponent_anchor_content_index,
  block_hero_text_standard: BlokkliComponent_hero_text_standard_index,
  block_downloads_2_col_titlebar: BlokkliComponent_downloads_2_col_titlebar_index,
  block_disruptor_1_col_titlebar: BlokkliComponent_disruptor_1_col_titlebar_index,
  block_icon_teaser_3_col: BlokkliComponent_icon_teaser_3_col_index,
  block_icon_teaser_2_col_titlebar: BlokkliComponent_icon_teaser_2_col_titlebar_index,
  block_icon_text_2_col_titlebar_tabs: BlokkliComponent_icon_text_2_col_titlebar_tabs_index,
  block_icon_text_2_col_titlebar: BlokkliComponent_icon_text_2_col_titlebar_index,
  block_icon_text_3_col: BlokkliComponent_icon_text_3_col_index,
  block_icon_text_3_col_tabs: BlokkliComponent_icon_text_3_col_tabs_index,
  block_image_fall_off: BlokkliComponent_image_fall_off_index,
  block_image_slider_fall_off_left: BlokkliComponent_image_slider_fall_off_left_index,
  block_image_fall_off_stripe: BlokkliComponent_image_fall_off_stripe_index,
  block_navigation_breadcrumb_teaser: BlokkliComponent_navigation_breadcrumb_teaser_index,
  block_quotes_slider: BlokkliComponent_quotes_slider_index,
  block_navigation_tabs_teaser: BlokkliComponent_navigation_tabs_teaser_index,
  block_teaser_3_col_lg: BlokkliComponent_teaser_3_col_lg_index,
  block_references_slider: BlokkliComponent_references_slider_index,
  block_text_1_col_standard: BlokkliComponent_text_1_col_standard_index,
  block_number_3_col: BlokkliComponent_number_3_col_index,
  block_text_1_col_stripe: BlokkliComponent_text_1_col_stripe_index,
  block_text_1_col_titlebar: BlokkliComponent_text_1_col_titlebar_index,
  block_text_2_col_scrollnavigation: BlokkliComponent_text_2_col_scrollnavigation_index,
  block_text_2_col_standard: BlokkliComponent_text_2_col_standard_index,
  block_text_2_col_tabs: BlokkliComponent_text_2_col_tabs_index,
  block_text_2_col_tabs_titlebar: BlokkliComponent_text_2_col_tabs_titlebar_index,
  block_text_3_col_standard: BlokkliComponent_text_3_col_standard_index,
  block_text_2_col_titlebar: BlokkliComponent_text_2_col_titlebar_index,
  block_text_3_col_tabs: BlokkliComponent_text_3_col_tabs_index,
  block_text_image_2_col_standard: BlokkliComponent_text_image_2_col_standard_index,
  block_text_number_3_col: BlokkliComponent_text_number_3_col_index,
  block_text_number_2_col_titlebar: BlokkliComponent_text_number_2_col_titlebar_index,
  block_text_image_2_col_bg_color_text: BlokkliComponent_text_image_2_col_bg_color_text_index,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index,
  block_video_content_width: BlokkliComponent_video_content_width_index,
  block_text_image_2_col_bg_color: BlokkliComponent_text_image_2_col_bg_color_index
}

    


const globalFragments: Record<string, any> = {
  
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare')
}

const chunkMapping: Record<string, string> = {}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
