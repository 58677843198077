<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex tab-s:gv-0">

        <div class="etx-flex__item Flex__item tab-s:c-0 show-0 tab-s:show-1">

          <div class="BrandshapeWrapper falling-off-left desk-l:falling-off-max-left" :class="entranceClassList">

            <div class="Brandshape" :data-color-main="stripeColorMain" :data-color-accent="stripeColorAccent">
              <svg width="208" height="208">
                <use :xlink:href="stripeShape"></use>
              </svg>
            </div>

          </div>

        </div>

        <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-7 tab-s:ml-2 tab-m:ml-3 tab-l:ml-4">

          <NuxtPageDependency>
            <Breadcrumb :isEditing="isEditing" :links="breadcrumb" v-if="showBreadcrumb"/>
          </NuxtPageDependency>

          <div class="etx-text Text" :class="entranceClassList">
            <h2 v-if="headingType == 'h2'" :class="[headingStyle, {'font-color-secondary': lead}]" >{{ heading }}</h2>
            <h3 v-if="headingType == 'h3'" :class="[headingStyle, {'font-color-secondary': lead}]" >{{ heading }}</h3>
            <h4 v-if="headingType == 'h4'" :class="[headingStyle, {'font-color-secondary': lead}]" >{{ heading }}</h4>
            <p v-if="lead" v-html="lead.replace(/\n/g, '<br>')" class="font-lead"></p>
            <HelperHtmlRenderer :htmlContent="text"/>
          </div>

          <div class="etx-button-list ButtonList" :class="entranceClassList" v-if="buttons.length > 0">
            <Button v-for="button in buttons" 
              :type="button?.type" 
              :targetBlank="button?.targetBlank"
              :href="button?.link?.uri?.path" 
              :label="button?.label" 
              :alt="button?.alt" 
            />
          </div>

          <!-- data-accordion-opened -->
          <div class="etx-accordion-list AccordionList">

            <div class="etx-accordion-list AccordionList" v-if="accordion.length > 0">
            
            <div v-for="accordionElement in accordion" class="etx-accordion Accordion" :class="entranceClassList">

              <div class="etx-accordion__header Accordion__header">

                <div class="etx-accordion__header__title Accordion__header__title" data-accordion-clickable="">
                  <p>{{ accordionElement.heading }}<span class="font-weight-regular font-color-primary" v-if="accordionElement.headingSubline">{{ accordionElement.headingSubline }}</span></p>
                </div>

                <div class="etx-accordion__header__button Accordion__header__button" data-accordion-clickable="">
                  <i class="far fa-plus"></i>
                </div>

              </div>

              <div class="etx-accordion__content Accordion__content">

                <div class="etx-text Text font-color-medium" v-if="accordionElement.text">
                  <HelperHtmlRenderer :htmlContent="accordionElement.text"/>
                </div>
                
                <div class="etx-button-list ButtonList" v-if="accordionElement.buttons.length > 0">
                  <Button v-for="button in accordionElement.buttons" 
                    :type="button?.type" 
                    :targetBlank="button?.targetBlank"
                    :href="button?.link?.uri?.path" 
                    :label="button?.label" 
                    :alt="button?.alt"
                  />
                </div>

              </div>
              
            </div>
            
          </div>

          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  showBreadcrumb: boolean
  heading: string
  headingType: string
  headingStyle: string
  lead: string
  text: string
  buttons: Array<any>
  accordion: Array<any>
  stripe: string
}>()

const breadcrumb = useBreadcrumbLinks()

const { options, isEditing } = defineBlokkli({
  bundle: 'text_1_col_stripe',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

const stripeShape = computed(() => {
  return '#brandshape-' + props.stripe.split('_')[0]
})

const stripeColorMain = computed(() => {
  return props.stripe.split('_')[1]
})

const stripeColorAccent = computed(() => {
  return props.stripe.split('_')[2]
})


</script>