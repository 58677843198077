<template>

  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-text Text" :class="entranceClassList" v-if="heading">
        <p class="font-h3 font-color-primary">{{ heading }}</p>
      </div>

      <div class="etx-spacer Spacer h-50" v-if="heading"></div>


      <div class="etx-flex Flex tab-s:c-2 tab-l:c-3 av-stretch">

        <div v-for="teaser in teasers" class="etx-flex__item Flex__item">

          <div class="etx-card Card CardLink large bg-primary has-link" :class="{
              'bg-secondary': teaser.color === 'bg-secondary',
              'bg-primary': teaser.color === 'bg-primary',
              'will-appear move-up': !isEditing
            }">

            <a class="etx-card__link Card__link" :href="teaser?.link?.uri?.path" title=""></a>

            <div class="etx-card__item Card__item pv-0 grow-1"></div>

            <div class="etx-card__item Card__item mob-l:ph-150 mob-l:pv-125">

              <div class="etx-item Item fullwidth av-bottom">
                <div class="etx-item__text Item__text">
                  <p v-if="teaser.text" v-html="teaser.text.replace(/\n/g, '<br>')" class="font-size-largest font-weight-bold"></p>
                </div>

                <div class="etx-item__icon Item__icon">

                  <div class="Icon large">
                    <i class="far fa-long-arrow-right"></i>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  heading: string
  teasers: Array<any>
}>()


const { options, isEditing } = defineBlokkli({
  bundle: 'teaser_3_col_lg',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})


</script>