<template>
  <div class="etx-flex__item Flex__item tab-l:c-4 desk-s:c-3">
    <div class="etx-card Card CardTableContents bg-grey-1" :class="entranceClassList">
      <div class="etx-card__item Card__item mob-l:ph-150 mob-l:pv-150">	
        
        <div class="etx-text Text">
          <p class="font-h4">Inhalte</p>
        </div>
        
        <div class="etx-item-list ItemList">
          
          <a class="etx-item Item" v-for="anchor in anchors.filter(anchor => anchor.label)" :key="anchor.id" :href="`#${anchor.id}`">		
            <div class="etx-item__text Item__text">
              <span class="font-weight-bold">{{ anchor.label }}</span>
            </div>
              
            <div class="etx-item__icon Item__icon">
              <i class="far fa-long-arrow-down"></i>	
            </div>
          </a>	
                    
        </div>
        
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

import { ref, onMounted } from 'vue';
import { useEventBus } from '~/composables/useEventBus';

const anchors = ref([]);
const eventBus = useEventBus();

onMounted(() => {
  anchors.value = eventBus.value;
});

</script>