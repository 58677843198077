<template>
  <a class="etx-button Button" 
    :class="type"
    :href="href"
    :title="alt"
    :target="target"
  >
    <div class="etx-button__text Button__text">
      <span>{{ label }}</span>
    </div>
    <div class="etx-button__icon Button__icon">
      <i class="far fa-long-arrow-right"></i>
    </div>
  </a>     
</template>

<script lang="ts" setup>

const props = defineProps<{
  type?: string
  href?: string
  label?: string
  alt?: string
  targetBlank?: boolean
}>()

const target = computed(() => {
  if (props.targetBlank) {
    return "_blank"
  }

  return "_self"
})

</script>