<template>
  <div class="AnchorPoint" data-anchor-offset="true" id="content">
    <p v-if="isEditing">Anker: Content Start</p>
  </div>
</template>

<script lang="ts" setup>

import { useEventBus } from '~/composables/useEventBus';

const { options, isEditing } = defineBlokkli({
  bundle: 'anchor_content',
})

const eventBus = useEventBus();

onMounted(() => {
  eventBus.value.push({ id: 'content' });
});

</script>