<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex tab-l:gv-0 tab-l:av-stretch">

        <div class="etx-flex__item Flex__item tab-l:c-7 desk-s:c-8" :class="layoutClassList">

          <div class="FixedRatio ratio-3-2 tab-l:max-height-none" :class="entranceClassList">

            <div class="etx-background-media BackgroundMedia">

              <div class="etx-media Media" v-if="image">
                <img style="aspect-ratio: 3 / 2; object-fit: cover;" :src="image?.url.path" :alt="image?.alt.alt" :title="image?.title" />
              </div>

            </div>

          </div>

        </div>

        <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-5 desk-s:c-4">

          <div class="etx-card Card" :class="bgColorBoxClassList, entranceClassList">
            <div class="etx-card__item Card__item ph-150 pv-150">

              <div class="etx-flex Flex">
                <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-12">

                  <div class="etx-text Text" v-if="heading || lead || text">
                    <h2 v-if="headingType == 'h2'" class="font-h4">{{ heading }}</h2>
                    <h3 v-if="headingType == 'h3'" class="font-h4">{{ heading }}</h3>
                    <h4 v-if="headingType == 'h4'" class="font-h4">{{ heading }}</h4>
                    <p class="font-size-large font-weight-bold font-color-primary" v-if="lead" v-html="lead.replace(/\n/g, '<br>')"></p>
                    <HelperHtmlRenderer :htmlContent="text"/>
                  </div>

                  <div class="etx-button-list ButtonList" v-if="buttons?.length > 0">
                    <Button v-for="button in buttons"
                      :type="button?.type"
                      :targetBlank="button?.targetBlank"
                      :href="button?.link?.uri?.path"
                      :label="button?.label" :alt="button?.alt"
                    />
                  </div>

                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  heading: string
  headingType: string
  lead: string
  text: string
  buttons: Array<any>
  image?: {
    url: {
      path: string
    }
    alt: {
      alt: string
    }
    title: string
  }
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'text_image_2_col_bg_color_text',
  globalOptions: ['spacingTop', 'spacingBottom'],
  options: {
    bgColorBox : {
      type: 'radios',
      label: 'Hintergrundfarbe',
      default: 'bg-grey-1',
      displayAs: 'colors',
      options: {
        'bg-grey-1': { class: 'bg-grey-1', label: 'Light grey' },
        'bg-secondary': { class: 'bg-secondary', label: 'Blue' },
      },
    },
    layout: {
      type: 'radios',
      label: 'Layout',
      default: 'right',
      options: {
        'right': 'Bild rechts',
        'left': 'Bild links',
      },
    },
  },
})


const layoutClassList = computed(() => {

  const classList = []

  const layoutClassMap = {
    right: 'tab-l:o-2',
    left: '',
  }

  if (options.value.layout && layoutClassMap[options.value.layout]) {
    classList.push(layoutClassMap[options.value.layout])
  }

  return classList
})

const bgColorBoxClassList = computed(() => {

  const classList = []

  if (options.value.bgColorBox) {
    classList.push(options.value.bgColorBox)
  }

  return classList
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

</script>