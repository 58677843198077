<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex gv-150 tab-l:gv-0 tab-l:av-stretch">

        <div class="etx-flex__item Flex__item tab-l:c-7 desk-s:c-8" :class="layoutClassList">

          <div class="FixedRatio ratio-3-2" :class="imageSizeClassList, entranceClassList">

            <div class="etx-background-media BackgroundMedia">

              <div class="etx-media Media" v-if="image">
                <img style="aspect-ratio: 3 / 2; object-fit: cover;" :src="image?.url.path" :alt="image?.alt.alt" :title="image?.title" />
              </div>

            </div>

          </div>

        </div>

        <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-5 desk-s:c-4">

          <div class="etx-card Card">
            <div class="etx-card__item Card__item ph-0 pv-0 grow-1"></div>

            <div class="etx-card__item Card__item ph-0 tab-l:pr-150 pv-0">

              <div class="etx-text Text" :class="entranceClassList" v-if="heading || lead || text">
                <h2 v-if="headingType == 'h2'" class="font-h3" :class="{'font-color-secondary': lead}">{{ heading }}</h2>
                <h3 v-if="headingType == 'h3'" class="font-h3" :class="{'font-color-secondary': lead}">{{ heading }}</h3>
                <h4 v-if="headingType == 'h4'" class="font-h3" :class="{'font-color-secondary': lead}">{{ heading }}</h4>
                <p class="font-size-large font-weight-bold font-color-primary" v-if="lead" v-html="lead.replace(/\n/g, '<br>')"></p>
                <HelperHtmlRenderer :htmlContent="text"/>
              </div>

              <div class="etx-button-list ButtonList" :class="entranceClassList" v-if="buttons?.length > 0">
                <Button v-for="button in buttons"
                  :type="button?.type"
                  :targetBlank="button?.targetBlank"
                  :href="button?.link?.uri?.path"
                  :label="button?.label" :alt="button?.alt"
                />
              </div>

            </div>


            <div class="etx-card__item Card__item ph-0 pv-0 grow-1"></div>
          </div>


        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  heading: string
  headingType: string
  lead: string
  text: string
  buttons: Array<any>
  image?: {
    url: {
      path: string
    }
    alt: {
      alt: string
    }
    title: string
  }
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'text_image_2_col_standard',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
  options: {
    layout: {
      type: 'radios',
      label: 'Layout',
      default: 'right',
      options: {
        'right': 'Bild rechts',
        'left': 'Bild links',
      },
    },
    imageSize: {
      type: 'radios',
      label: 'Bildgrösse',
      default: 'content-width',
      options: {
        'content-width': 'Inhaltsbreite',
        'fall-off': 'Randabfallend',
      },
    }
  },
})


const layoutClassList = computed(() => {

  const classList = []

  const layoutClassMap = {
    right: 'tab-l:o-2',
    left: '',
  }

  if (options.value.layout && layoutClassMap[options.value.layout]) {
    classList.push(layoutClassMap[options.value.layout])
  }

  return classList
})

const imageSizeClassList = computed(() => {

  const classList = []

  const imageSizeClassMap = {
    'content-width': '',
    'fall-off': `falling-off-${options.value.layout} desk-l:falling-off-max-${options.value.layout}`,
  }

  if (options.value.imageSize && imageSizeClassMap[options.value.imageSize]) {
    classList.push(imageSizeClassMap[options.value.imageSize])
  }

  return classList
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

</script>