<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex tab-l:gv-0">

        <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-4 desk-s:c-3">

          <div class="etx-text Text" :class="entranceClassList">
            <h2 v-if="headingType == 'h2'" class="font-h3 font-color-primary" >{{ heading }}</h2>
            <h3 v-if="headingType == 'h3'" class="font-h3 font-color-primary" >{{ heading }}</h3>
            <h4 v-if="headingType == 'h4'" class="font-h3 font-color-primary" >{{ heading }}</h4>
            <p class="font-h3 alt" v-if="headingSubline">{{ headingSubline }}</p>
          </div>

        </div>

        <div class="etx-flex__item Flex__item" :class="wrapperClassList">

          <div v-if="lead || text" class="etx-text Text" :class="entranceClassList">
            <p v-if="lead" v-html="lead.replace(/\n/g, '<br>')" class="font-lead"></p>
            <HelperHtmlRenderer :htmlContent="text"/>
          </div>

          <div class="etx-button-list ButtonList" :class="entranceClassList" v-if="buttons.length > 0">
            <Button v-for="button in buttons"
              :type="button?.type"
              :targetBlank="button?.targetBlank"
              :href="button?.link?.uri?.path"
              :label="button?.label"
              :alt="button?.alt"
            />
          </div>

        </div>

        <div class="etx-flex__item Flex__item tab-s:c-1 tab-m:c-2 tab-s:ml-1 tab-l:ml-0 show-0 tab-s:show-1" v-if="showStripe">

          <div class="BrandshapeWrapper falling-off-right desk-l:falling-off-max-right" :class="entranceClassList">
            <div class="Brandshape" :data-color-main="stripeColorMain" :data-color-accent="stripeColorAccent">
              <svg width="208" height="208">
                <use :xlink:href="stripeShape"></use>
              </svg>
            </div>
          </div>

        </div>


      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  heading: string
  headingType: string
  headingSubline: string
  lead: string
  text: string
  buttons: Array<any>
  stripe: string
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'text_1_col_titlebar',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

const showStripe = computed(() => {
  return props.stripe
})

const stripeShape = computed(() => {
  return '#brandshape-' + props.stripe.split('_')[0]
})

const stripeColorMain = computed(() => {
  return props.stripe.split('_')[1]
})

const stripeColorAccent = computed(() => {
  return props.stripe.split('_')[2]
})

const wrapperClassList = computed(() => {

  if (props.stripe) {
    return ['tab-s:c-10', 'tab-m:c-9', 'tab-l:c-5', 'desk-s:c-6', 'tab-l:ml-1']
  }

  return ['tab-s:c-10', 'tab-m:c-9', 'tab-l:c-7', 'desk-s:c-8', 'tab-l:ml-1']
})

</script>