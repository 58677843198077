<template>

  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex gh-200 gv-150 tab-s:c-2 tab-l:c-3">

        <div v-for="contentCol in contentCols" class="etx-flex__item Flex__item">

          <div class="etx-card Card" :class="entranceClassList">

            <div class="etx-card__item Card__item ph-0 pt-0 pb-50" v-if="contentCol.icon">
              <div class="etx-icon Icon large font-color-primary">
                <i class="far" :class="getFontawesomeClass(contentCol.icon)"></i>
              </div>

            </div>

            <div class="etx-card__item Card__item ph-0 pv-0" v-if="contentCol.heading || contentCol.lead || contentCol.text">

              <div class="etx-text Text font-color-medium">
                <h3 v-if="contentCol.headingType == 'h3'" :class="contentCol.headingStyle">{{ contentCol.heading }}</h3>
                <h4 v-if="contentCol.headingType == 'h4'" :class="contentCol.headingStyle">{{ contentCol.heading }}</h4>
                <p v-if="contentCol.headingType == 'p'" :class="contentCol.headingStyle">{{ contentCol.heading }}</p>
                <p v-if="contentCol.lead" v-html="contentCol.lead.replace(/\n/g, '<br>')"></p>
                <HelperHtmlRenderer :htmlContent="contentCol.text"/>
              </div>

            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  contentCols: Array<any>
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'icon_text_3_col',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

const getFontawesomeClass = (icon: string): string => {
  return 'fa-' + icon;
};

</script>