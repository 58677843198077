<template>
  <div class="etx-container Container pt-0" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-card Card CardDisruptor bg-secondary shrink-to-width" :class="entranceClassList"data-angle-position="top-right" data-angle-color="primary">
        <div class="etx-card__item Card__item ph-150 pv-150 mob-m:ph-200 mob-m:pv-200 tab-l:ph-0 tab-l:pv-0">

          <div class="etx-spacer Spacer h-0 tab-l:h-75"></div>

          <div class="etx-flex Flex gv-200 tab-m:gv-0 tab-m:av-center">

            <div class="etx-flex__item Flex__item tab-m:c-6 tab-l:c-4 tab-l:ml-1">

              <div class="etx-text Text" v-if="heading || headingSubline || text">
                <h2 v-if="headingType == 'h2'" class="font-color-primary" :class="headingStyle !== 'standard' ? headingStyle : ''">{{ heading }}</h2>
                <h3 v-if="headingType == 'h3'" class="font-color-primary" :class="headingStyle !== 'standard' ? headingStyle : ''">{{ heading }}</h3>
                <h4 v-if="headingType == 'h4'" class="font-color-primary" :class="headingStyle !== 'standard' ? headingStyle : ''">{{ heading }}</h4>
                <p v-if="headingSubline" class="font-h2 alt">{{ headingSubline }}</p>
                <HelperHtmlRenderer :htmlContent="text" />
              </div>

              <div v-if="buttons.length > 0" class="etx-button-list ButtonList" :class="entranceClassList">
                <Button v-for="button in buttons" 
                  :type="button?.type" 
                  :targetBlank="button?.targetBlank"
                  :href="button?.link?.uri?.path" 
                  :label="button?.label" 
                  :alt="button?.alt" 
                />
              </div>
            </div>

            <div v-if="textBlocks.length > 0" class="etx-flex__item Flex__item tab-m:c-5 tab-m:ml-1">

              <div v-for="textBlock in textBlocks" class="etx-text Text">
                <h3 v-if="textBlock.heading && textBlock.headingType == 'h3'" class="font-size-larger font-weight-bold">{{ textBlock.heading }}</h3>
                <h4 v-if="textBlock.heading && textBlock.headingType == 'h4'" class="font-size-larger font-weight-bold">{{ textBlock.heading }}</h4>
                <p v-if="textBlock.heading && textBlock.headingType == 'p'" class="font-size-larger font-weight-bold">{{ textBlock.heading }}</p>
                <HelperHtmlRenderer :htmlContent="textBlock.text" />
              </div>

            </div>

          </div>

          <div class="etx-spacer Spacer h-0 tab-l:h-75"></div>

        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  heading: string
  headingType: string
  headingStyle: string
  headingSubline: string
  text: string
  buttons: Array<any>
  textBlocks: Array<any>
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'disruptor_1_col_titlebar',
  globalOptions: ['spacingTop', 'spacingBottom'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

</script>