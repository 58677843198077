import type { InitData } from '~/composables/useInitData'
import type { MenuLinkTreeFragment } from '#graphql-operations'

function isLinkTreeActive(
  tree: MenuLinkTreeFragment & { subtree?: Array<MenuLinkTreeFragment> },
  currentRoutePath: string,
): boolean {
  if (tree.link.url?.path === currentRoutePath) {
    return true
  }

  if (tree.subtree) {
    return tree.subtree.some((subtreeEl) =>
      isLinkTreeActive(subtreeEl, currentRoutePath),
    )
  }

  return false
}

function getActiveSubtree(
  tree: Array<MenuLinkTreeFragment>,
  currentRoutePath: string,
): MenuLinkTreeFragment | null {

  for (const item of tree) {
    if (item?.link?.url?.path === currentRoutePath) {
      return item;
    }
    if (item?.subtree) {
      const activeSubtree = getActiveSubtree(item?.subtree, currentRoutePath);
      if (activeSubtree) {
        return activeSubtree;
      }
    }
  }
  return null;

}


export async function useMainMenuLinks() {
  const initData: Ref<InitData> = await useInitData()
  const currentRoute = useRoute()

  const links = computed(() => {
    return initData.value.mainMenuLinks
  })

  const currentActiveMainLink = computed(() => {
    return initData.value.mainMenuLinks.find((l) =>
      isLinkTreeActive(l, currentRoute.path),
    )
  })

  const activeLocalLink = computed(() => {
    return currentActiveMainLink.value?.subtree.find((l) =>
      isLinkTreeActive(l, currentRoute.path),
    )
  })

  const isHotelActive = computed(() => {
    return isLinkTreeActive(initData.value.mainMenuLinks[2], currentRoute.path)
  })

  const activeChildLinks = computed(() => {
    return getActiveSubtree(initData.value.mainMenuLinks, currentRoute.path)?.subtree
  })

  return {
    links,
    currentActiveMainLink,
    activeLocalLink,
    isHotelActive,
    activeChildLinks
  }
}
