<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">
      <div class="etx-flex Flex gv-0">
        <div class="etx-flex__item Flex__item tab-s:c-10 desk-s:c-8 tab-s:ml-2 desk-s:ml-4">
          <div class="swiper Swiper SwiperImages" :class="entranceClassList" :data-swiper-id="id">
            <div class="Swiper__controls">
              <div class="swiper-pagination Swiper__controls__pagination"></div>
            </div>
            <div class="swiper-wrapper Swiper__wrapper">
              <div v-for="image in images" class="swiper-slide Swiper__wrapper__slide">
                <div class="etx-media Media" :class="entranceClassList">
                  <img style="aspect-ratio: 3 / 2; object-fit: cover;" 
                    :src="image?.url.path" 
                    :alt="image?.alt.alt"
                    :title="image?.title" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { integer } from '@elastic/elasticsearch/lib/api/types';

const props = defineProps<{
  id: integer
  images?: Array<Object>
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'image_slider_fall_off_left',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})


const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

</script>