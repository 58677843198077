<template>

  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">
      <div class="FixedRatio ratio-3-2" :class="wrapperClassList">

        <div class="etx-background-media BackgroundMedia">

          <div class="etx-media Media">
            <img style="aspect-ratio: 3 / 2; object-fit: cover;" :src="image?.url.path" :alt="image?.alt.alt" :title="image?.title" />
          </div>

        </div>

      </div>

    </div>
  </div>

</template>

<script lang="ts" setup>
defineProps<{
  image?: any
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'image_fall_off',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
  options: {
    fallOff: {
      type: 'radios',
      label: 'Randabfallend',
      default: 'left',
      options: {
        'left': 'Nur links',
        'right': 'Nur rechts',
        'both': 'Beidseitig',
      },
    }
  },
})

const imageStyle = computed(() => {
  return defineImageStyle({
    type: 'sizes',
    aspectRatio: 3 / 2,
    sizes: {
      sm: 768,
      md: 800,
    },
  })
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const wrapperClassList = computed(() => {

  const classList = []

  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }

  if (options.value.fallOff === 'both') {
    classList.push('falling-off-left', 'falling-off-right', 'desk-l:falling-off-max-left', 'desk-l:falling-off-max-right')
  }

  if (options.value.fallOff === 'left') {
    classList.push('falling-off-left', 'desk-l:falling-off-max-left')
  }

  if (options.value.fallOff === 'right') {
    classList.push('falling-off-right', 'desk-l:falling-off-max-right')
  }



  return classList
})

</script>