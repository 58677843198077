<template>

  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">
      <div class="FixedRatio ratio-3-2 falling-off-left falling-off-right desk-l:falling-off-max-left desk-l:falling-off-max-right" :class="wrapperClassList">

        <div class="Brandshape" :data-color-main="stripeColorMain" :data-color-accent="stripeColorAccent">
          <svg width="208" height="208">
            <use :xlink:href="stripeShape"></use>
          </svg>
        </div>

        <div class="etx-background-media BackgroundMedia">
          <div class="etx-media Media">
            <img style="aspect-ratio: 3 / 2; object-fit: cover;" :src="image?.url.path" :alt="image?.alt.alt" :title="image?.title" />
          </div>

        </div>

      </div>

    </div>
  </div>

</template>

<script lang="ts" setup>

const props = defineProps<{
  image?: any
  stripe: string
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'image_fall_off_stripe',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const imageStyle = computed(() => {
  return defineImageStyle({
    type: 'sizes',
    aspectRatio: 3 / 2,
    sizes: {
      sm: 768,
      md: 800,
    },
  })
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const wrapperClassList = computed(() => {

  const classList = []

  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }

  return classList
})

const stripeShape = computed(() => {
  return '#brandshape-' + props?.stripe?.split('_')[0]
})

const stripeColorMain = computed(() => {
  return props?.stripe?.split('_')[1]
})

const stripeColorAccent = computed(() => {
  return props?.stripe?.split('_')[2]
})


</script>