<template>

  <div class="etx-container Container" :class="paragraphClassList">

    <div class="swiper Swiper SwiperQuotes" :class="entranceClassList" :data-swiper-id="id">

      <div class="swiper-wrapper Swiper__wrapper">

        <div v-for="quote in quotes" :key="quote.id" class="swiper-slide Swiper__wrapper__slide">

          <div class="etx-center Center">

            <div class="etx-flex Flex gv-0 tab-m:gv-0 av-stretch">

              <div class="etx-flex__item Flex__item c-0 tab-m:c-4">

                <div class="etx-media Media">
                  <img style="aspect-ratio: 4 / 5; object-fit: cover;" :src="quote?.image?.url.path" alt:="quote?.image?.alt.alt" :title="quote?.image?.title" />
                </div>

              </div>

              <div class="etx-flex__item Flex__item tab-m:c-7 tab-m:ml-1">

                <div class="etx-card Card will-appear" data-angle-position="top-left" data-angle-color="secondary">

                  <div class="etx-card__item Card__item pl-150 pr-0 pt-150 pb-200 grow-1">

                    <div class="etx-text Text">
                      <blockquote>
                        <p v-if="quote.text" v-html="quote.text.replace(/\n/g, '<br>')"></p>
                      </blockquote>
                    </div>

                  </div>

                  <div class="etx-card__item Card__item pl-150 pr-0 pv-0">

                    <div class="etx-text Text">
                      <p class="font-size-larger font-weight-bold">{{ quote.firstName}} <span class="font-uppercase">{{ quote.lastName}}</span>
                      </p>
                      <p class="font-color-medium" v-if="quote.function">{{ quote.function }}</p>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

      <div class="Swiper__controls">

        <div class="etx-flex Flex">
          <div class="etx-flex__item Flex__item tab-m:ml-5">

            <div class="swiper-pagination Swiper__controls__pagination"></div>

          </div>
        </div>

      </div>

    </div>

  </div>

</template>

<script lang="ts" setup>
import type { integer } from '@elastic/elasticsearch/lib/api/types';

const props = defineProps<{
  id?: integer
  quotes: Array<Object>
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'quotes_slider',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const imageStyle = computed(() => {
  return defineImageStyle({
    type: 'sizes',
    aspectRatio: 4 / 5,
    sizes: {
      sm: 768,
      md: 800,
    },
  })
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const wrapperClassList = computed(() => {

  const classList = []

  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})


</script>