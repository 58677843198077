<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">
      
      <div class="etx-flex Flex gh-200 gv-150 tab-l:gv-0">
        
        <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-6">
          
          <div class="etx-text Text" :class="entranceClassList" v-if="colLeftHeading || colLeftLead || colLeftText">
            <h2 v-if="colLeftHeadingType == 'h2'" :class="[colLeftHeadingStyle, {'font-color-secondary': colLeftLead}]" >{{ colLeftHeading }}</h2>
            <h3 v-if="colLeftHeadingType == 'h3'" :class="[colLeftHeadingStyle, {'font-color-secondary': colLeftLead}]" >{{ colLeftHeading }}</h3>
            <h4 v-if="colLeftHeadingType == 'h4'" :class="[colLeftHeadingStyle, {'font-color-secondary': colLeftLead}]" >{{ colLeftHeading }}</h4>
            <p v-if="colLeftLead" v-html="colLeftLead.replace(/\n/g, '<br>')" class="font-lead"></p>
            <HelperHtmlRenderer :htmlContent="colLeftText"/>
          </div>
          
          <div class="etx-button-list ButtonList" :class="entranceClassList" v-if="colLeftButtons.length > 0">
            <Button v-for="button in colLeftButtons" 
              :type="button?.type" 
              :targetBlank="button?.targetBlank"
              :href="button?.link?.uri?.path" 
              :label="button?.label" 
              :alt="button?.alt"
            />
          </div>

          <div class="etx-accordion-list AccordionList" v-if="colLeftAccordion.length > 0">
            
            <div v-for="accordionElement in colLeftAccordion" class="etx-accordion Accordion" :class="entranceClassList">

              <div class="etx-accordion__header Accordion__header">

                <div class="etx-accordion__header__title Accordion__header__title" data-accordion-clickable="">
                  <p>{{ accordionElement.heading }}<span class="font-weight-regular font-color-primary" v-if="accordionElement.headingSubline">{{ accordionElement.headingSubline }}</span></p>
                </div>

                <div class="etx-accordion__header__button Accordion__header__button" data-accordion-clickable="">
                  <i class="far fa-plus"></i>
                </div>

              </div>

              <div class="etx-accordion__content Accordion__content">

                <div class="etx-text Text font-color-medium" v-if="accordionElement.text">
                  <HelperHtmlRenderer :htmlContent="accordionElement.text"/>
                </div>
                
                <div class="etx-button-list ButtonList" v-if="accordionElement.buttons.length > 0">
                  <Button v-for="button in accordionElement.buttons" 
                    :type="button?.type" 
                    :targetBlank="button.targetBlank"
                    :href="button?.link?.uri?.path" 
                    :label="button?.label" 
                    :alt="button?.alt" 
                  />
                </div>

              </div>

            </div>
            
          </div>
          
        </div>
        
        <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-6">
          
          <div class="etx-text Text" :class="entranceClassList" v-if="colRightHeading || colRightLead || colRightText">
            <h2 v-if="colRightHeadingType == 'h2'" :class="[colRightHeadingStyle, {'font-color-secondary': colRightLead}]" >{{ colRightHeading }}</h2>
            <h3 v-if="colRightHeadingType == 'h3'" :class="[colRightHeadingStyle, {'font-color-secondary': colRightLead}]" >{{ colRightHeading }}</h3>
            <h4 v-if="colRightHeadingType == 'h4'" :class="[colRightHeadingStyle, {'font-color-secondary': colRightLead}]" >{{ colRightHeading }}</h4>
            <p v-if="colRightLead" v-html="colRightLead.replace(/\n/g, '<br>')" class="font-lead"></p>
            <HelperHtmlRenderer :htmlContent="colRightText"/>
          </div>
          
          <div class="etx-button-list ButtonList" :class="entranceClassList" v-if="colRightButtons.length > 0">
            <Button v-for="button in colRightButtons" 
              :type="button?.type" 
              :targetBlank="button.targetBlank"
              :href="button?.link?.uri?.path" 
              :label="button?.label" 
              :alt="button?.alt" 
            />
          </div>
          

          <div class="etx-accordion-list AccordionList" v-if="colRightAccordion.length > 0">
            
            <div v-for="accordionElement in colRightAccordion" class="etx-accordion Accordion" :class="entranceClassList">

              <div class="etx-accordion__header Accordion__header">

                <div class="etx-accordion__header__title Accordion__header__title" data-accordion-clickable="">
                  <p>{{ accordionElement.heading }}<span class="font-weight-regular font-color-primary" v-if="accordionElement.headingSubline">{{ accordionElement.headingSubline }}</span></p>
                </div>

                <div class="etx-accordion__header__button Accordion__header__button" data-accordion-clickable="">
                  <i class="far fa-plus"></i>
                </div>

              </div>

              <div class="etx-accordion__content Accordion__content">

                <div class="etx-text Text font-color-medium" v-if="accordionElement.text">
                  <HelperHtmlRenderer :htmlContent="accordionElement.text"/>
                </div>
                
                <div class="etx-button-list ButtonList" v-if="accordionElement.buttons.length > 0">
                  <Button v-for="button in accordionElement.buttons" 
                    :type="button?.type" 
                    :targetBlank="button?.targetBlank"
                    :href="button?.link?.uri?.path" 
                    :label="button?.label" 
                    :alt="button?.alt" />
                </div>

              </div>

            </div>
            
          </div>
          
        </div>
        
      </div>
      
    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  colLeftHeading: string
  colLeftHeadingType: string
  colLeftHeadingStyle: string
  colLeftLead: string
  colLeftText: string
  colLeftButtons: Array<any>
  colLeftAccordion: Array<any>

  colRightHeading: string
  colRightHeadingType: string
  colRightHeadingStyle: string
  colRightLead: string
  colRightText: string
  colRightButtons: Array<any>
  colRightAccordion: Array<any>
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'text_2_col_standard',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

const flexWrapperClass = computed(() => {
  return ['tab-s:gv-0']
})

</script>