<template>
    <p>Text: 2 Spalten, Tabs</p>
  </template>
  
  <script lang="ts" setup>
  
  const { options, isEditing } = defineBlokkli({
    bundle: 'text_2_col_tabs',
  })
  
  </script>
  