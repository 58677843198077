<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex gv-200 tab-l:gv-0">

        <ScrollNavigation :isEditing="isEditing" />

        <div class="etx-flex__item Flex__item tab-l:c-7 desk-s:c-8 tab-l:ml-1" v-if="contentCols.length > 0">

          <div class="etx-flex Flex gh-200 gv-150 mob-l:c-2">

            <div class="etx-flex__item Flex__item" v-for="contentCol in contentCols">

              <div class="etx-text Text" :class="entranceClassList" v-if="contentCol.heading || contentCol.lead || contentCol.text">
                <h3 v-if="contentCol.headingType == 'h3'" :class="[contentCol.headingStyle, {'font-color-secondary': contentCol.lead}]">{{ contentCol.heading }}</h3>
                <h4 v-if="contentCol.headingType == 'h4'" :class="[contentCol.headingStyle, {'font-color-secondary': contentCol.lead}]">{{ contentCol.heading }}</h4>
                <p v-if="contentCol.headingType == 'p'" :class="[contentCol.headingStyle, {'font-color-secondary': contentCol.lead}]">{{ contentCol.heading }}</p>
                <p v-if="contentCol.lead" v-html="contentCol.lead.replace(/\n/g, '<br>')"></p>
                <HelperHtmlRenderer :htmlContent="contentCol.text"/>
              </div>            

            </div>

          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  contentCols: Array<any>
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'text_2_col_scrollnavigation',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

</script>