<template>
  <div class="etx-container Container pv-0" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="etx-flex Flex gv-0 av-stretch">

        <div class="etx-flex__item Flex__item tab-l:c-7" :class="layoutClassList">

          <div class="FixedRatio ratio-3-2" :class="layoutInnerImageClassList">

            <div class="etx-background-media BackgroundMedia">

              <div class="etx-media Media" v-if="image">
                <img style="aspect-ratio: 3 / 2; object-fit: cover;" :src="image?.url.path" :alt="image?.alt.alt" :title="image?.title" />
              </div>

            </div>

          </div>

        </div>

        <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-5">

          <div class="etx-card Card">
            <div class="etx-card__item Card__item ph-0 pv-0 grow-1"></div>

              <div class="etx-card__item Card__item ph-0 pv-150 tab-l:pv-200" :class="layoutInnerTextClassList">

                <div class="etx-text Text" :class="entranceClassList" v-if="heading || lead || text">
                  <h2 v-if="headingType == 'h2'" class="font-h3">{{ heading }}</h2>
                  <h3 v-if="headingType == 'h3'" class="font-h3">{{ heading }}</h3>
                  <h4 v-if="headingType == 'h4'" class="font-h3">{{ heading }}</h4>
                  <p class="font-size-large font-weight-bold font-color-primary" v-if="lead" v-html="lead.replace(/\n/g, '<br>')"></p>
                  <HelperHtmlRenderer :htmlContent="text"/>
                </div>

                <div class="etx-button-list ButtonList" :class="entranceClassList" v-if="buttons?.length > 0">
                  <Button v-for="button in buttons"
                    :type="button?.type"
                    :targetBlank="button?.targetBlank"
                    :href="button?.link?.uri?.path"
                    :label="button?.label" :alt="button?.alt"
                  />
                </div>

              </div>

            <div class="etx-card__item Card__item ph-0 pv-0 grow-1"></div>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  heading: string
  headingType: string
  lead: string
  text: string
  buttons: Array<any>
  image?: {
    url: {
      path: string
    }
    alt: {
      alt: string
    }
    title: string
  }
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'text_image_2_col_bg_color',
  options: {
    bgColorBox : {
      type: 'radios',
      label: 'Hintergrundfarbe',
      default: 'bg-grey-1',
      displayAs: 'colors',
      options: {
        'bg-grey-1': { class: 'bg-grey-1', label: 'Light grey' },
        'bg-secondary': { class: 'bg-secondary', label: 'Blue' },
      },
    },
    layout: {
      type: 'radios',
      label: 'Layout',
      default: 'right',
      options: {
        'right': 'Bild rechts',
        'left': 'Bild links',
      },
    },
  },
})


const layoutClassList = computed(() => {

  const classList = []

  const layoutClassMap = {
    right: 'tab-l:o-2',
    left: '',
  }

  if (options.value.layout && layoutClassMap[options.value.layout]) {
    classList.push(layoutClassMap[options.value.layout])
  }

  return classList
})

const layoutInnerImageClassList = computed(() => {

  const classList = []

  const layoutInnerImageClassMap = {
    right: 'falling-off-left falling-off-right tab-l:falling-off-left-none desk-l:falling-off-max-right',
    left: 'falling-off-left falling-off-right tab-l:falling-off-right-none desk-l:falling-off-max-left',
  }

  if (options.value.layout && layoutInnerImageClassMap[options.value.layout]) {
    classList.push(layoutInnerImageClassMap[options.value.layout])
  }

  return classList
})

const layoutInnerTextClassList = computed(() => {

  const classList = []

  const layoutInnerTextClassMap = {
    right: 'tab-l:pr-150',
    left: 'tab-l:pl-150',
  }

  if (options.value.layout && layoutInnerTextClassMap[options.value.layout]) {
    classList.push(layoutInnerTextClassMap[options.value.layout])
  }

  return classList
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.bgColorBox) {
    classList.push(options.value.bgColorBox)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

</script>