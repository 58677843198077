<template>
    <p>Referenzen: Slider, Highlights</p>
  </template>
  
  <script lang="ts" setup>
  
  const { options, isEditing } = defineBlokkli({
    bundle: 'references_slider',
  })
  
  </script>
  