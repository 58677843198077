<template>
  <div class="AnchorPoint" :id="anchorId" :data-anchor-name="label" data-anchor-offset="true">
    <p v-if="isEditing">Anker: {{ label }}</p>
  </div>
</template>

<script lang="ts" setup>

import { onMounted } from 'vue';
import { useEventBus } from '~/composables/useEventBus';

const props = defineProps<{
  id: string
  label: string
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'anchor',
})

const anchorId = computed(() => {
  if (props.id) {
    return props.id;
  }
  return props.label.replace(/[^a-zA-Z0-9-]/g, '');
})

const eventBus = useEventBus();

onMounted(() => {
  // Register the anchor on the event bus
  eventBus.value.push({ id: anchorId, label: props.label });
});

</script>