<template>
  <div v-if="links.length > 1" class="Breadcrumb" :class="entranceClassList">
    <div v-for="link in links" class="Breadcrumb__item">
      <a v-if="link.url.path" :href="link.url.path" :title="link.title">{{ link.title }}</a>
      <span v-if="!link.url.path">{{ link.title }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>

import type { BreadcrumbFragment } from '#graphql-operations'
import type { PageLanguage } from '#language-negotiation/language'

const props = defineProps<{
  links: BreadcrumbFragment[]
  language?: PageLanguage
  isEditing?: boolean
}>()

const list = ref<HTMLOListElement | null>(null)


const entranceClassList = computed(() => {

  const classList = []

  if (!props.isEditing) {
    classList.push('will-appear', 'move-up')
  }

  return classList
})

</script>
