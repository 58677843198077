<template>
  <div class="etx-container Container" :class="paragraphClassList">
    <div class="etx-center Center">

      <div class="Tabs" v-if="tabs.length">

        <div class="Tabs__header" :class="entranceClassList">
            <div v-for="(tab, index) in tabs" :key="tab.id" class="Tabs__header__item" :class="{ active: activeTab === index }" @click="activeTab = index" :data-tab-id="'tab-' + tab.id">
              <span>{{ tab.tabTitle }}</span>
            </div>
        </div>

        <div class="Tabs__content">

          <div v-for="(tab, index) in tabs" :key="tab.id" class="Tabs__content__item" :class="{ active: activeTab === index }" :data-tab-id="'tab-' + tab.id">

            <div class="etx-flex Flex tab-s:gv-0">
              <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-10">

                <div class="etx-text Text" v-if="tab.heading || tab.lead || tab.text" :class="entranceClassList">
                  <h2 v-if="tab.headingType == 'h2'" :class="tab.headingStyle">{{ tab.heading }}</h2>
                  <h3 v-if="tab.headingType == 'h3'" :class="tab.headingStyle">{{ tab.heading }}</h3>
                  <h4 v-if="tab.headingType == 'h4'" :class="tab.headingStyle">{{ tab.heading }}</h4>
                  <p v-if="tab.lead" class="font-lead" v-html="tab.lead.replace(/\n/g, '<br>')"></p>
                  <HelperHtmlRenderer :htmlContent="tab.text" />
                </div>

              </div>
            </div>

            <div class="etx-spacer Spacer h-50" v-if="(tab.heading || tab.lead || tab.text) && tab.contentCols.length"></div>

            <div class="etx-flex Flex gh-200 gv-150 tab-s:c-2 tab-l:c-3">

              <div v-for="contentCol in tab.contentCols" class="etx-flex__item Flex__item">

                <div class="etx-flex Flex c-auto nowrap gh-75 gv-0" :class="entranceClassList">

                  <div v-if="contentCol.icon" class="etx-flex__item Flex__item shrink-0">

                    <div class="etx-icon Icon large font-color-primary">
                      <i class="far" :class="getFontawesomeClass(contentCol.icon)"></i>
                    </div>

                  </div>

                  <div class="etx-flex__item Flex__item grow-1" v-if="contentCol.heading || contentCol.text">

                    <div class="etx-text Text font-color-medium">
                      <p v-if="contentCol.heading" class="font-size-large font-weight-bold font-color-secondary">{{ contentCol.heading }}</p>
                      <HelperHtmlRenderer :htmlContent="contentCol.text"/>
                    </div>

                  </div>

                </div>

              </div>

            </div>

            <div class="etx-spacer Spacer h-50" v-if="tab.contentCols?.length && tab.buttons?.length"></div>

            <div class="etx-flex Flex tab-s:gv-0">
              <div class="etx-flex__item Flex__item tab-s:c-10 tab-m:c-9 tab-l:c-10">

                <div class="etx-button-list ButtonList" :class="entranceClassList" v-if="tab.buttons?.length > 0">
                  <Button v-for="button in tab.buttons" :type="button?.type" :targetBlank="button?.targetBlank"
                    :href="button?.link?.uri?.path" :label="button?.label" :alt="button?.alt" />
                </div>

              </div>
            </div>

          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  tabs: Array<{
    id: string
    tabTitle: string
    heading?: string
    headingType?: string
    headingStyle?: string
    lead?: string
    text: string
    contentCols: Array<{
      icon?: string
      heading?: string
      text?: string
    }>
    buttons?: Array<{
      type?: string
      targetBlank?: boolean
      link?: {
        uri?: {
          path?: string
        }
      }
      label?: string
      alt?: string
    }>
  }>
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'icon_text_3_col_tabs',
  globalOptions: ['spacingTop', 'spacingBottom', 'backgroundColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacingTop) {
    classList.push(options.value.spacingTop)
  }

  if (options.value.spacingBottom) {
    classList.push(options.value.spacingBottom)
  }

  if (options.value.backgroundColor !== 'white') {
    classList.push(options.value.backgroundColor)
  }

  return classList
})

const entranceClassList = computed(() => {
  const classList = []
  if (!isEditing) {
    classList.push('will-appear', 'move-up')
  }
  return classList
})

const getFontawesomeClass = (icon: string): string => {
  return 'fa-' + icon;
};

const activeTab = ref(0);

</script>